import axios from "axios"

const ExternalAPIRequest = async (details) => {
    const {baseUrl, data} = details
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Vary': 'origin'
            }
        }

        const response = await axios.post(baseUrl, data, config)

        return response.data

    } catch (error) {
        let statusCode = 500
        let statusMessage = 'Internal server error - ' + error.message

        if (error.response) {
            statusCode = error.response.status || error.response.statusCode || error.code
            statusMessage = 'Api service error - ' + (error.response.statusText|| error.message)
        } else if (error.request) {
            statusCode = 408
            statusMessage = 'Api service error - Network error, please try again.'
        }

        return { success: false, code: statusCode, message: statusMessage, logMessage: statusMessage }
    }
}

export default ExternalAPIRequest
