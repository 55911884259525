import React, { useState } from 'react'
import { withStyles } from "@material-ui/core/styles"

import Loader from "../Loader/Loader";
import CopyButton from '../Buttons/CopyButton';

import {
  Card,
  CardContent,
  Typography,
  TextField,
  Button,
  Box,
  Divider,
} from '@material-ui/core'

import {
  ExpandMore,
  ExpandLess,
  FileCopyOutlined as FileCopy,
} from '@material-ui/icons'

const styles = theme => ({
  card: {
    width: '100%',
    height: '100%',
    boxShadow: theme.shadows[2],
  },
  cardContent: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  divider: {
    marginBottom: '20px',
    marginTop: '20px',
  },
  responseHeader: {
    display: 'flex',
    justifyContent: 'space-between', // Align header items horizontally
    alignItems: 'center',
    marginBottom: theme.spacing(1),
  },
  responseTypography: {
    textAlign: 'left',
    paddingTop: '6px'
  },
  responseContainer: {
    position: 'relative',
  },
  loader: {
    paddingRight: '20px'
  },
  expandButton: {
    marginBottom: '5px',
    padding: '11px 0px 8px',
  },
})

const APIResponseDisplay = ({ classes, response }) => {
  const [showResponse, setShowResponse] = useState(true)

  return (
    <>
    <Card className={classes.card}>
      <CardContent className={classes.cardContent}>
        <Box className={classes.responseHeader}>
          
            <Typography variant="h6" className={classes.responseTypography}>Response</Typography>

            <Button onClick={() => setShowResponse(prev => !prev)} className={classes.expandButton}>
              {showResponse ? <ExpandLess /> : <ExpandMore />}
             </Button>
        </Box>
        <Divider className={classes.divider} />
        {showResponse && (
          <Box
          className={classes.responseContainer}
          >
              <Box position="relative" mt={2}>

                <TextField
                      fullWidth
                      multiline
                      rows={15}
                      variant="outlined"
                      value={response}
                      disabled={true}
                      id='responseDisplay'
                    />
                <CopyButton text={response}/>
              </Box>
          </Box>
        )}
      </CardContent>
    </Card>
    </>
  )
}

export default withStyles(styles)(APIResponseDisplay)