import React, { useEffect, useState, useRef } from 'react'
import { connect } from "react-redux"

import { getProviders, getServices,} from "../../cache/selectors"

import ClientsController from "../../Controllers/ClientsController"
import DataController from "../../Controllers/DataController"

import { withStyles } from "@material-ui/core/styles"

import Loader from "../Loader/Loader";
import Alert from "../Alert/Alert";

import {
  Card,
  CardContent,
  Typography,
  TextField,
  Select,
  MenuItem,
  Box,
  Divider,
  Checkbox,
  FormControl,
  FormHelperText,
  InputLabel,
  Grid,
  Tooltip
} from '@material-ui/core'

import Info from '@material-ui/icons/Info';

const styles = theme => ({
  card: {
    width: '100%',
    height: '100%',
    boxShadow: theme.shadows[2],
  },
  cardContent: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  configHeader: {
    textAlign: 'left',
    display: 'flex',
    alignItems: 'center',
  },
  info: {
    marginLeft: '10px',
  },
  configSelect: {
    textAlign: 'left',
    marginBottom: '10px',
  },
  divider: {
    marginBottom: '20px',
    marginTop: '20px',
  },
  locationTextField: {
    marginBottom: '10px',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectForm: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
  },
  loader: {
    paddingRight: '20px',
  },
  gridOverlayMessage: {
    padding: '50px'
  }
})

const mapStateToProps = state => {
  const providerData = getProviders(state);
  const serviceData = getServices(state);
  return { providerData, serviceData }
}

const APIConfigContainer = ({
  classes,
  handleLoading,
  handleRequestDetails,
  handleErrorType,
  errorType,
  requestDetails,
  addAlert,
  serviceData,
  providerData,
}) => {

  const [data, setData] = useState([])
  const [externalApiData, setExternalApiData] = useState({
    apiData: {},
  })
  const [selectedItems, setSelectedItems] = useState({
    client: { name: '' },
    service: { name: '', full_name: ''},
    provider: { name: '', full_name: ''},
  })
  const [options, setOptions] = useState({
    client: [{ name: '' }],
    service: [{ name: '', full_name: ''}],
    provider: [{ name: '', full_name: ''}],
  })
  const [servicesLoaded, setServicesLoaded] = useState(false)
  const [providersLoaded, setProvidersLoaded] = useState(false)

  const locationParams = [
    {type: 'latitude', label: 'Latitude'},
    {type: 'longitude', label: 'Longitude'},
    {type: 'siteALatitude', label: 'Site A Latitude'},
    {type: 'siteALongitude', label: 'Site A Longitude'},
    {type: 'siteBLatitude', label: 'Site B Latitude'},
    {type: 'siteBLongitude', label: 'Site B Longitude'},
    {type: 'postcode', label: 'Postcode'}
  ]

  useEffect(() => {
    const fetchData = async () => {
      try {
        const subscriptionData = await ClientsController.GetSubscriptions()
        const apiData = await DataController.GetExternalAPIs()

        handleLoading(false)

        const subscriptionApiData = subscriptionData.filter(s=>s.use_external_api)

        if (!subscriptionApiData) throw {message: 'Failed loading data.'}

        setExternalApiData((prev) => {return {...prev, apiData}})
        const remappedData = remapArray(subscriptionApiData)
        const clientNames = Object.keys(remappedData)
        
        setOptions((prev) => {
           const clients = clientNames.map(client => {
            return { name: client}
          });

          return ({...prev, client: clients})
        })

        setData(remappedData)
      } catch (error) {
        handleLoading(false)
        addAlert({
          message: error.message || 'Error retrieving data, please refresh page and try again.',
          state: 'error',
          open: true,
        })
      }
    }
  
    fetchData()
  }, [])

  const remapArray = (data) => {
    return data.reduce((acc, { client, provider, service, ...rest }) => {
        // Check if the client already exists
        if (!acc[client]) {
            acc[client] = {}
        }

        // Check if the service already exists for this client
        if (!acc[client][service]) {
            acc[client][service] = {}
        }

        acc[client][service][provider] = rest

        return acc
    }, {})
  }

  const parseOptions = (selection) => {
    if(!selection) return

    const client = data[selection.client.name]

    let services = client ? Object.keys(client) : null

    const serviceSelections = selection.service.name ? client[selection.service.name] : null

    let providers = serviceSelections ? Object.keys(data[selection.client.name][selection.service.name]) : null

    if (services) {
      filterOption(services, serviceData, setOptions, setServicesLoaded, 'service')
    }
    if (providers) {
      filterOption(providers, providerData, setOptions, setProvidersLoaded, 'provider')
    }
  }

  const filterOption = (options, optionsData, setState, setLoaded, type) => {
    options = options.map(d => {
      const tmpObj = optionsData.find(s => d === s.name)
      return {
        name: tmpObj.name,
        full_name: tmpObj.full_name
      }
    })

    setState((prev) => {
      return {...prev, [type]: options}
    })
    setLoaded(true)
  }

  const handleSelectChange = async (event) => {
    const { name, value} = event.target

    handleErrorType((prev) => {
      return {
        ...prev,
        requestError: false,
        requestBodyError: false
      }
    })

    const resetLoadedState = () => {
      setServicesLoaded(false)
      setProvidersLoaded(false)
      handleRequestDetails({baseUrl: '', data: parseLocationParams(requestDetails.data), apiConfig: '', externalApi: '', requiredParams: []})
    }

    const parseLocationParams = (data) => {
      if(!data) return

      const updatedDetails = {}

      if(data.latitude) updatedDetails.latitude = data.latitude
      if(data.longitude) updatedDetails.longitude = data.longitude
      if(data.postcode) updatedDetails.postcode = data.postcode

      return updatedDetails
    }

    await setSelectedItems((prev) => {
      let updatedItems
      if (name === 'client') {
        updatedItems = {
          ...prev, 
          [name.toLowerCase()]: { name: value.name },
          service: { name: '', full_name: '' },
          provider: { name: '', full_name: '' },
        }
        resetLoadedState()
      } else if (name === 'service') {
        updatedItems = {
          ...prev,
          [name.toLowerCase()]: { name: value.name, full_name: value.full_name },
          provider: { name: '', full_name: ''},
        }
        resetLoadedState()
      } else {
        updatedItems = {
          ...prev, 
          [name.toLowerCase()]: { name: value.name, full_name: value.full_name }
        }
      }
      
      parseOptions(updatedItems)
      createRequestDetails(updatedItems)
      return updatedItems
    })
  }

  const createRequestDetails = (currentSelectedItems) => {
    const { client, service, provider } = currentSelectedItems

    if (!client || !provider.name || !service.name ) return
  
      const requestInfo = JSON.parse(data[client.name][service.name][provider.name].external_api_details)
  
    if (requestInfo) {
      try {
         
        handleLoading(false)

        const selectedExternalAPI = externalApiData.apiData.find(api => api.provider === provider.name && api.service === service.name)

        if (!selectedExternalAPI) throw { code: 404, message: `External API not found.` }

        const externalUrl = selectedExternalAPI.api_url

        const updatedDetails = parseDetails(requestInfo, selectedExternalAPI.request_config)

        handleRequestDetails({
          baseUrl: externalUrl, 
          data: updatedDetails.data, 
          apiConfig: selectedExternalAPI.request_config, 
          externalApi: `${provider.full_name} External API`,
          requiredParams: updatedDetails.requiredParams
        })
      } catch (e) {
        const errMessage = e.message || 'Invalid Request'
        addAlert({
          message: errMessage,
          state: 'error',
          open: true,
        })
        handleLoading(false)
      }
    }
  }

  const handleLocationChange = (value, type) => {
    if (requestDetails && requestDetails.data) {
      try {
        let data = requestDetails.data
        if (typeof requestDetails.data !== 'object') {
          data = JSON.parse(requestDetails.data)
        }

        const updatedRequestDetails = {
          ...requestDetails,
          data: {
            ...data,
            [type]: value,
          },
        }
  
        handleRequestDetails(updatedRequestDetails)
  
      } catch (error) {
        const errMessage = 'Error parsing requestDetails'
        addAlert({
          message: errMessage,
          state: 'error',
          open: true,
        })
      }
    }
  }

  const parseDetails = (data, apiConfig) => {
    if(!data || !apiConfig) return

    const requiredParams = {}

    const configKeys = Object.keys(apiConfig)
    
    configKeys.forEach(d => {
      if(!data[d] ) {
        if (apiConfig[d].required) {
          data[d] = 'Parameter Required'
          requiredParams[d] = apiConfig[d].required
        } else {
          data[d] = ''
        }
      }
    })

    locationParams.forEach(item => {
      if(apiConfig[item.type]) {
        data[item.type] = requestDetails.data[item.type] ? requestDetails.data[item.type] : ''
      }
    })


    return {
      data,
      requiredParams
    }

  }

  return (
    <Card className={classes.card}>
      <CardContent className={classes.cardContent}>
        <Box className={classes.infoContainer}>
          <Typography variant="h6" className={classes.configHeader}>
            Request Configuration
            <Tooltip title="Only external API subscriptions listed." className={classes.info}>
              <Info aria-label="external api subscriptions" />
            </Tooltip>
          </Typography>
        </Box>
        <Divider className={classes.divider} />
        <Box className={classes.selectForm} variant='outlined'>
        {['client', 'service', 'provider'].map((item, index) => (
          <FormControl required key={index} error={!selectedItems[item].name && errorType.requestError}>
          <InputLabel id={`select-${item}-label`}>Select {item}</InputLabel>
            <Select
              fullWidth
              name={item}
              labelId={`select-${item}-label`}
              value={selectedItems[item].full_name || selectedItems[item].name}
              onChange={handleSelectChange}
              
              disabled={item === 'service' && !servicesLoaded || item === 'provider' && !providersLoaded}
              renderValue={(value) => (value ? value : `Select ${item}`)}
              className={classes.configSelect}
            >
              {
                options[item].map((option) => (
                  <MenuItem key={option.name} value={{name: option.name, full_name: option.full_name || option.name}}>
                    {option.full_name || option.name}
                  </MenuItem>
                ))
              }
            </Select>
            <FormHelperText>{!selectedItems[item].name && errorType.requestError ? "Selection Required" : ""}</FormHelperText>
          </FormControl>
        ))}
        </Box>
        
        {/* Location Params */}
        <Divider className={classes.divider} />
        <Typography variant="subtitle1" gutterBottom style={{ marginBottom: '10px', textAlign: 'left' }}>Required Location Parameters</Typography>
        <Grid container spacing={1}> 
        {requestDetails.apiConfig && locationParams.map(item => (
          requestDetails.apiConfig[item.type] && (
            <Grid item xs={12} sm={6} className={classes.gridItem}>
              <TextField
                required={requestDetails.requiredParams[item.type] ? true : false}
                disabled={requestDetails.apiConfig ? !requestDetails.apiConfig[item.type] : true}
                label={item.label}
                value={requestDetails.data[item.type]}
                onChange={(e) => handleLocationChange(e.target.value, item.type)}
                helperText={!requestDetails.data[item.type] && errorType.missingInputError && requestDetails.requiredParams[item.type] ? `${item.label} is required` : ""}
                error={!requestDetails.data[item.type] && errorType.missingInputError && requestDetails.requiredParams[item.type]}
                fullWidth
                className={classes.locationTextField}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          )
        ))} 
        {!requestDetails.apiConfig && 
          <Grid xs={12} sm={12} className={classes.gridOverlayMessage}>
            <p style={{ marginBottom: '10px', textAlign: 'center', color: 'grey'}}>No required location parameters found</p>
          </Grid>
        }
        </Grid>
      </CardContent>
    </Card>
  )
}

export default withStyles(styles)(connect(mapStateToProps)(APIConfigContainer))