import React, { useState } from 'react'
import Alert from "../Alert/Alert";
import { withStyles } from "@material-ui/core/styles"

import {
  Button,
} from '@material-ui/core'

import {
  FileCopyOutlined as FileCopy,
} from '@material-ui/icons'

const styles = theme => ({
  copyButton: {
      position: 'absolute',
      bottom: '10px',
      right: '10px',
  },
})

const CopyButton = ({classes, text}) => {
  const [alertState, setAlertState] = useState([{
    message: '',
    state: '',
    open: false,
  }])

  const handleCopy = () => {
    try {
      navigator.clipboard.writeText(text)
      addAlert({
        message: `Data Copied Successfully.`,
        state: 'success',
        open: true,
      })
    } catch (error) {
      addAlert({
        message: `Error copying data: ${error}`,
        state: 'error',
        open: true,
      })
    }
    
  }

  const addAlert = (newAlert) => {
    setAlertState((prevAlerts) => [...prevAlerts, newAlert]);
  };

  const handleAlertClose = (alertToClose) => {
    setAlertState((prevAlerts) => prevAlerts.filter(alert => alert !== alertToClose));
  }

  return (
    <>
      {alertState.map((alert, index) => (
      <Alert
        {...alert}
        handleClose={() => handleAlertClose(alert)}
      />
    ))}
    <Button 
      size="small"
      className={classes.copyButton}
      onClick={handleCopy}
    >
      <FileCopy/>
    </Button>
    </>
  )
}

export default withStyles(styles)(CopyButton)