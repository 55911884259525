import React, { useEffect, useState, useRef } from 'react'
import { withStyles } from "@material-ui/core/styles"
import {
  Box,
  CircularProgress,
} from '@material-ui/core'

const styles = theme => ({
  loader: {
    position:'absolute',
    top:0,
    left:0,
    right:0,
    bottom:0,
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    backgroundColor:'rgba(255, 255, 255, 0.7)',
    zIndex:1000,
  }
})

const Loader = (props) => {
  const { classes } = props

  return (
    <Box className={classes.loader} style={props.style}>
      <CircularProgress />
    </Box>
  )
}


export default withStyles(styles)(Loader)