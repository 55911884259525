import React, { useState, } from 'react'
import { withStyles } from "@material-ui/core/styles"

import CopyButton from '../Buttons/CopyButton'

import {
  Card,
  CardContent,
  Typography,
  TextField,
  Button,
  Box,
  Tabs,
  Tab,
  Divider,
  Tooltip,
  Grid,
} from '@material-ui/core'

import {
  Info,
} from '@material-ui/icons'

const styles = theme => ({
  card: {
    width: '100%',
    height: '100%',
    boxShadow: theme.shadows[2],
  },
  cardContent: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  divider: {
    marginBottom: '20px',
    marginTop: '20px',
  },
  requestHeader: {
    marginBottom: '10px',
    textAlign: 'left',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  info: {
    marginLeft: '10px',
  },
  requestComponents: {
    height: '55px',
  },
  requestButton: {
    height: '100%'
  },
  requestTabs: {
    position: "relative",
    overflowY: 'auto',
    padding: '5px',
    marginBottom: '5px',
    maxHeight: '335px'
  },
  apiConfig: {
    marginTop: '53px',
  },
})

const APIRequestContainer = ({
    classes,
    loading,
    requestDetails,
    handleRequest,
    handleRequestDetails,
    handleErrorType,
    errorType,
    addAlert,
  }) => {
  const [tabValue, setTabValue] = useState('jsonBody')

  const handleEditBody = (data) => {
    let newData = data

    if(!data) newData = JSON.stringify({latitude: requestDetails.data.latitude || '', longitude: requestDetails.data.longitude || ''}, null, 4)

    const valid = validateJSONBody(newData)

    if(valid) {
      newData = JSON.parse(newData)
    }

    handleRequestDetails((prev) => {
      return {...prev, data: newData}
    })
  }

  const validateJSONBody = (body) => {
    let valid = false

    if (typeof body === 'object') {
      body = JSON.stringify(body, null, 4); // Pretty format JSON
    }

    try {
      const validJSON = JSON.parse(body)
      handleErrorType((prev) => {return {...prev, requestBodyError: false}})
      valid = true
    } catch (error) {
      handleErrorType((prev) => {return {...prev, requestBodyError: true}})
      addAlert({
        message: `Invalid JSON found in JSON body`,
        state: 'error',
        open: true,
      })
    }
    
    return valid
  }

  const formatDetails = (data) => {
    if (typeof data === 'object') {
      return JSON.stringify(data, null, 4); // Pretty format JSON
    }
    return data
  }

  const handleTabSelection = (value) => {
    setTabValue(value)
  }

  return (
        <Card className={classes.card}>
          <CardContent className={classes.cardContent}>
            <Typography variant="h6" gutterBottom className={classes.requestHeader}>
              Make a Request
              <Tooltip title="Request details will be prepopulated upon config selection, however some required and optional parameters might require manual input." className={classes.info}>
                <Info aria-label="request details" />
              </Tooltip>
            </Typography>
            <Divider className={classes.divider} />

            {/* Request setup */}
            <Grid container spacing={2} className={classes.requestDetails}>
  
              {/* Request URL */}
              <Grid item xs={12} sm={9}>
                <TextField
                  fullWidth
                  disabled
                  label="Selected External API"
                  variant="outlined"
                  value={requestDetails.externalApi}
                  className={classes.requestComponents}
                />
              </Grid>
  
              {/* Request Button */}
              <Grid item xs={12} sm={3}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={(e) => handleRequest()}
                  fullWidth
                  className={classes.requestButton}
                  disabled={loading}
                >
                  Send Request
                </Button>
              </Grid>
            </Grid>
  
              {/* Tabs Section */}
              <Grid item>
                <Grid container spacing={2}>
                  {/* Tabs */}
                  <Grid item xs={12} md={7}>
                    <Tabs
                      value={tabValue}
                      onChange={(e, newValue) => handleTabSelection(newValue)}
                      variant="scrollable"
                      scrollButtons="auto"
                      aria-label="Request tabs"
                      fullWidth
                    >
                      <Tab label="JSON Body" value="jsonBody" />
                    </Tabs>

                      {/* POST Request JSON Body Tab */}
                      {tabValue === 'jsonBody' && (
                        <Box className={classes.requestTabs}>
                          <TextField
                            fullWidth
                            label={ "JSON Body"}
                            multiline
                            rows={15}
                            variant="outlined"
                            onChange={(e) => handleEditBody(e.target.value)}
                            className={classes.jsonBodyText}
                            value={formatDetails(requestDetails.data)}
                            placeholder="{ }"
                            error={errorType.requestBodyError}
                          />
                          <CopyButton text={requestDetails.data} />
                        </Box>
                      )}
                  </Grid>

                  {/* API Config Tab (Right-aligned) */}
                  <Grid item xs={12} md={5}>
                    <Box className={classes.apiConfig}>
                      <TextField
                        fullWidth
                        label="External API Config"
                        multiline
                        rows={15}
                        variant="outlined"
                        value={JSON.stringify(requestDetails.apiConfig, null, 4)}
                        disabled
                      />
                    </Box>
                  </Grid>
                </Grid>

            </Grid>
          </CardContent>
        </Card>
  )
}

export default withStyles(styles)(APIRequestContainer)